@import url("https://fonts.googleapis.com/css?family=Inconsolata");
@import url("https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css");

@font-face {
  font-family: "JHA";
  src: url("./type/TimesNow-SemiLight.woff2") format("woff2"),
    url("./type/TimesNow-SemiLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-Regular.woff2") format("woff2"),
    url("./type/Favorit-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-BoldItalic.woff2") format("woff2"),
    url("./type/Favorit-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-Medium.woff2") format("woff2"),
    url("./type/Favorit-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-LightItalic.woff2") format("woff2"),
    url("./type/Favorit-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-Bold.woff2") format("woff2"),
    url("./type/Favorit-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-RegularItalic.woff2") format("woff2"),
    url("./type/Favorit-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-Light.woff2") format("woff2"),
    url("./type/Favorit-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url("./type/Favorit-MediumItalic.woff2") format("woff2"),
    url("./type/Favorit-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "GTF";
  src: url("./type/GTF.woff2") format("woff2"),
    url("./type/GTF.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GTF";
  src: url("./type/GTFI.woff2") format("woff2"),
    url("./type/GTFI.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "RN";
  src: url("./type/RingsideNarrow-Semibold.woff2") format("woff2"),
    url("./type/RingsideNarrow-Semibold.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "RN";
  src: url("./type/RingsideNarrow-Ultra.woff2") format("woff2"),
    url("./type/RingsideNarrow-Ultra.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "RN";
  src: url("./type/RingsideNarrow-Bold.woff2") format("woff2"),
    url("./type/RingsideNarrow-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "CH";
  src: url("./type/CooperHewitt-Bold.woff") format("woff"),
    url("./type/CooperHewitt-Bold.svg") format("svg"),
    url("./type/CooperHewitt-Bold.eot") format("eot");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "CH";
  src: url("./type/CooperHewitt-Heavy.woff") format("woff"),
    url("./type/CooperHewitt-Heavy.svg") format("svg"),
    url("./type/CooperHewitt-Heavy.eot") format("eot");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("./type/Circular-Book.woff2") format("woff2"),
    url("./type/Circular-Book.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url("./type/Circular-BookItalic.woff2") format("woff2"),
    url("./type/Circular-BookItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("./type/Circular-Medium.woff2") format("woff2"),
    url("./type/Circular-Medium.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("./type/Circular-Bold.woff2") format("woff2"),
    url("./type/Circular-Bold.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url("./type/Circular-Black.woff2") format("woff2"),
    url("./type/Circular-Black.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

body {
  -webkit-font-smoothing: antialiased;
}

.fade-appear,
.fade-enter {
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
  z-index: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: all 150ms linear;
}

.headroom,
.headroom--unfixed {
  position: fixed;
  top: 72px;
  width: calc(100vw - 2rem);
  left: 1rem;
  transform: translateY(0);
  z-index: 888;
  transition: transform 200ms ease-in-out;
}

@media (min-width: 1024px) {
  .headroom,
  .headroom--unfixed {
    top: 72px;
    left: calc(50% - 210px);
  }
}

@media (orientation: landscape) {
  .headroom,
  .headroom--unfixed {
    top: 72px;
    left: calc(50% - 210px);
  }
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--unpinned .shop-dropdown {
  display: none;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}
