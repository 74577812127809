@import url(https://fonts.googleapis.com/css?family=Inconsolata);
@import url(https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: "JHA";
  src: url(/static/media/TimesNow-SemiLight.babff96c.woff2) format("woff2"),
    url(/static/media/TimesNow-SemiLight.87b3e84b.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-Regular.ed8f7c23.woff2) format("woff2"),
    url(/static/media/Favorit-Regular.4134242c.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-BoldItalic.f10e743b.woff2) format("woff2"),
    url(/static/media/Favorit-BoldItalic.b9eeccea.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-Medium.e7f2b0ed.woff2) format("woff2"),
    url(/static/media/Favorit-Medium.908bbb7a.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-LightItalic.12fc1a25.woff2) format("woff2"),
    url(/static/media/Favorit-LightItalic.ebfb961a.woff) format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-Bold.e51e8332.woff2) format("woff2"),
    url(/static/media/Favorit-Bold.1a55ffd6.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-RegularItalic.ea60a232.woff2) format("woff2"),
    url(/static/media/Favorit-RegularItalic.0e4bb473.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-Light.87d11699.woff2) format("woff2"),
    url(/static/media/Favorit-Light.3eb81f32.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Favorit";
  src: url(/static/media/Favorit-MediumItalic.0f3d49da.woff2) format("woff2"),
    url(/static/media/Favorit-MediumItalic.f1c59998.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "GTF";
  src: url(/static/media/GTF.249237ef.woff2) format("woff2"),
    url(/static/media/GTF.26c539f8.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GTF";
  src: url(/static/media/GTFI.249237ef.woff2) format("woff2"),
    url(/static/media/GTFI.26c539f8.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "RN";
  src: url(/static/media/RingsideNarrow-Semibold.f4bc300e.woff2) format("woff2"),
    url(/static/media/RingsideNarrow-Semibold.2fdd0a51.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "RN";
  src: url(/static/media/RingsideNarrow-Ultra.a5af1f4e.woff2) format("woff2"),
    url(/static/media/RingsideNarrow-Ultra.10ce3ddb.woff) format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "RN";
  src: url(/static/media/RingsideNarrow-Bold.199432a0.woff2) format("woff2"),
    url(/static/media/RingsideNarrow-Bold.78b75e4e.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "CH";
  src: url(/static/media/CooperHewitt-Bold.ccd41c44.woff) format("woff"),
    url(/static/media/CooperHewitt-Bold.1f5bdd73.svg) format("svg"),
    url(/static/media/CooperHewitt-Bold.61a4ff8b.eot) format("eot");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "CH";
  src: url(/static/media/CooperHewitt-Heavy.7c379a0b.woff) format("woff"),
    url(/static/media/CooperHewitt-Heavy.f0695ace.svg) format("svg"),
    url(/static/media/CooperHewitt-Heavy.ee2da511.eot) format("eot");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url(/static/media/Circular-Book.74a24143.woff2) format("woff2"),
    url(/static/media/Circular-Book.94acc2b2.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Circular";
  src: url(/static/media/Circular-BookItalic.dfe9c770.woff2) format("woff2"),
    url(/static/media/Circular-BookItalic.a147cfbc.woff) format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url(/static/media/Circular-Medium.450c2ef6.woff2) format("woff2"),
    url(/static/media/Circular-Medium.860205c0.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url(/static/media/Circular-Bold.9c0ac24a.woff2) format("woff2"),
    url(/static/media/Circular-Bold.bc55ffc8.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Circular";
  src: url(/static/media/Circular-Black.cd6562f7.woff2) format("woff2"),
    url(/static/media/Circular-Black.840d37d5.woff) format("woff");
  font-weight: 800;
  font-style: italic;
}

body {
  -webkit-font-smoothing: antialiased;
}

.fade-appear,
.fade-enter {
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
  z-index: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: all 150ms linear;
}

.headroom,
.headroom--unfixed {
  position: fixed;
  top: 72px;
  width: calc(100vw - 2rem);
  left: 1rem;
  transform: translateY(0);
  z-index: 888;
  transition: transform 200ms ease-in-out;
}

@media (min-width: 1024px) {
  .headroom,
  .headroom--unfixed {
    top: 72px;
    left: calc(50% - 210px);
  }
}

@media (orientation: landscape) {
  .headroom,
  .headroom--unfixed {
    top: 72px;
    left: calc(50% - 210px);
  }
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--unpinned .shop-dropdown {
  display: none;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}

